import React from 'react'
// import { Link } from "gatsby"

import ApplySingle from '../components/apply/single'
import Header from '../components/header'
import IntroAlt from '../components/sections/intro/alt'
import Layout from '../components/layout'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import SEO from '../components/seo'

import family from '../images/family-beach.jpg'

const FullAltPage = ({ location }) => (
  <Layout phone="888-996-1365" location={location}>
    <SEO
      title="Auto Insurance Offers"
      keywords={[`life`, `insurance`, `plans`, `affordable`, `supplement`]}
    />
    <Header phone="888-996-1365" />
    <IntroAlt
      title="Fill out the form below for a <span>free</span> quote!"
      subTitle="Getting a quote is 100% free, fast, and secure."
      imgTitle="Protect what matters, when it matters most."
      imgText="Life gets complicated, but insuring it shouldn’t be. With Life Insurance Companion we’re there with you every step of the way to find the best policy for you. Every person is unique and we’ll help you find coverage that works with your life."
      colType="right-col"
      bgImage={family}
    >
      <ApplySingle className="input-borders full-width" />
    </IntroAlt>
    <main id="main">
      <Partners />
      <HomeContent />
    </main>
  </Layout>
)

export default FullAltPage
