import { connect } from 'react-redux'

import Single from './single'

import {
  preSubmitApplication,
  submitData,
  submitApplication,
  submitDob,
} from '../../../reducers/captive'
import { toggleModal } from '../../../reducers/modals'

export default connect((state) => state, {
  preSubmitApplication,
  submitData,
  submitDob,
  submitApplication,
  toggleModal,
})(Single)
